<template>
  <tr
    :key="item.id"
    :class="{
      'v-data-table__mobile-table-row': !$vuetify.breakpoint.mdAndUp,
    }"
    class="table-row"
    @click.stop="clickRow(item)"
    @mouseover="isHovered = true"
    @mouseleave="isHovered = false"
  >
    <td
      :class="{
        'v-data-table__mobile-row': !$vuetify.breakpoint.mdAndUp,
        'v-data-table__checkbox-row': $vuetify.breakpoint.mdAndUp,
      }"
      class="pt-2 text-center"
    >
      <div
        v-if="!$vuetify.breakpoint.mdAndUp"
        class="v-data-table__mobile-row__header"
      ></div>
      <div
        :class="{
          'v-data-table__mobile-row__cell': !$vuetify.breakpoint.mdAndUp,
        }"
      >
        <v-simple-checkbox
          :ripple="false"
          :value="isSelected"
          color="primary"
          @input="selectOnChange($event)"
        />
      </div>
    </td>
    <td
      class="pt-2 text-center"
      :class="{
        'v-data-table__mobile-row': !$vuetify.breakpoint.mdAndUp,
        'v-data-table__5-row': $vuetify.breakpoint.mdAndUp,
      }"
    >
      <div
        v-if="!$vuetify.breakpoint.mdAndUp"
        class="v-data-table__mobile-row__header"
      >
        <div>
          {{ $trans("id") }}
        </div>
      </div>
      <div
        :class="{
          'v-data-table__mobile-row__cell': !$vuetify.breakpoint.mdAndUp,
        }"
      >
        <div class="text-left">
          <div>{{ item.id }}</div>
        </div>
      </div>
    </td>
    <td
      :class="{
        'v-data-table__mobile-row': !$vuetify.breakpoint.mdAndUp,
        'v-data-table__40-row': $vuetify.breakpoint.mdAndUp,
      }"
      class="py-2"
    >
      <div
        v-if="!$vuetify.breakpoint.mdAndUp"
        class="v-data-table__mobile-row__header"
      >
        {{ $trans("simple_store_product_list_product_name") }}
      </div>
      <div
        :class="{
          'v-data-table__mobile-row__cell': !$vuetify.breakpoint.mdAndUp,
        }"
      >
        <div class="mb-2 mt-2">
          <div class="d-flex align-center mb-4 justify-end justify-md-start">
            <simple-store-product-card-details :product="item" />
            <v-chip
              x-small
              :color="$helpers.getStatusColor(item.status).color"
              :class="$helpers.getStatusColor(item.status).class"
              class="ml-2"
            >
              {{ $trans(item.status) }}
            </v-chip>
          </div>
        </div>
        <div v-if="item.success_url" class="mt-2 mb-2">
          {{ $trans("subscription_success_url") }}:
          <a :href="item.success_url" target="_blank" @click.stop>{{
            item.success_url
          }}</a>
        </div>
        <div v-if="item.cancel_url" class="mt-2 mb-2">
          {{ $trans("subscription_cancel_url") }}:
          <a :href="item.cancel_url" target="_blank" @click.stop>{{
            item.cancel_url
          }}</a>
        </div>
      </div>
    </td>
    <td
      :class="{
        'v-data-table__mobile-row': !$vuetify.breakpoint.mdAndUp,
        'v-data-table__15-row': $vuetify.breakpoint.mdAndUp,
      }"
      class="py-2"
    >
      <div
        v-if="!$vuetify.breakpoint.mdAndUp"
        class="v-data-table__mobile-row__header"
      >
        {{ $trans("simple_store_product_list_product_price") }}
      </div>
      <div
        :class="{
          'v-data-table__mobile-row__cell': !$vuetify.breakpoint.mdAndUp,
        }"
      >
        {{ item.price | money }}
      </div>
    </td>
    <td
      :class="{
        'v-data-table__mobile-row': !$vuetify.breakpoint.mdAndUp,
        'v-data-table__15-row': $vuetify.breakpoint.mdAndUp,
      }"
      class="py-2"
    >
      <div
        v-if="!$vuetify.breakpoint.mdAndUp"
        class="v-data-table__mobile-row__header"
      >
        {{ $trans("wants_invoice_title") }}
      </div>
      <div
        :class="{
          'v-data-table__mobile-row__cell': !$vuetify.breakpoint.mdAndUp,
        }"
      >
        <div class="d-flex align-center">
          <v-icon
            :color="item.wants_invoice ? 'green' : 'light'"
            class="mr-2"
            v-text="item.wants_invoice ? '$check-circle' : '$close-circle'"
          >
            $check-circle
          </v-icon>

          <div v-if="item.tax">{{ $trans("tax_label") }}: {{ item.tax }}</div>
        </div>
      </div>
    </td>
    <td
      :class="{
        'v-data-table__mobile-row': !$vuetify.breakpoint.mdAndUp,
        'v-data-table__15-row': $vuetify.breakpoint.mdAndUp,
      }"
      class="py-2"
    >
      <div
        v-if="!$vuetify.breakpoint.mdAndUp"
        class="v-data-table__mobile-row__header"
      >
        {{ $trans("created_at") }}
      </div>
      <div
        :class="{
          'v-data-table__mobile-row__cell': !$vuetify.breakpoint.mdAndUp,
        }"
      >
        {{ item.created_at | dateTime($helpers.hourDayMonthAndYearDate) }}
      </div>
    </td>
    <td
      :class="{
        'v-data-table__mobile-row': !$vuetify.breakpoint.mdAndUp,
        'v-data-table__manage-row': $vuetify.breakpoint.mdAndUp,
      }"
      class="py-2"
    >
      <div class="row-list-buttons white rounded mr-1 mt-2">
        <simple-store-product-list-buttons
          :is-hovered="isHovered"
          :item="item"
        />
      </div>
    </td>
  </tr>
</template>
<script>
import SimpleStoreProductListButtons from "@/views/dashboard/pages/Offer/SimpleProductStore/components/SimpleStoreProductListButtons.vue";
import SimpleStoreProductCardDetails from "@/components/SimpleStoreProductCardDetails.vue";
import itemRowActions from "@/calendesk/mixins/itemRowActions";

export default {
  components: {
    SimpleStoreProductCardDetails,
    SimpleStoreProductListButtons,
  },
  mixins: [itemRowActions],
};
</script>
