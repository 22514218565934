<template>
  <div v-if="!isSending" class="list mb-16">
    <v-container fluid>
      <v-row>
        <v-col>
          <calendesk-information-message>
            {{ $trans("upselling_info_2") }}
          </calendesk-information-message>
        </v-col>
      </v-row>
    </v-container>
    <no-data-info
      v-if="!simpleStoreProductExist"
      :description="$trans('no_simple_store_product_info')"
      :button-text="$trans('simple_store_product_add_title')"
      @click="handleAddProduct"
    />
    <template v-else>
      <simple-store-product-filter
        @filtersChanged="filtersChanged"
        @filtersRestored="filtersRestored"
      />
      <v-data-table
        v-model="selected"
        :footer-props="footerProps"
        :headers="headers"
        :items="items"
        :loading="isLoadingTable"
        :loading-text="$trans('loading')"
        :no-data-text="$trans('nothing_found_here')"
        :no-results-text="$trans('nothing_found_here')"
        :options.sync="tableOptions"
        :server-items-length="total"
        :show-select="!noData"
        class="calendesk-datatable"
        mobile-breakpoint="960"
      >
        <template #[`header.data-table-select`]>
          <div class="text-center">
            <v-simple-checkbox
              v-model="selectAllState"
              :ripple="false"
              :indeterminate="isIndeterminateForSelectAll"
              color="primary"
              @input="selectAllItemsOnChange"
            />
          </div>
        </template>

        <template #[`item`]="{ item }">
          <simple-store-product-row
            :item="item"
            :is-selected="
              isRowSelected({
                id: item.id,
              })
            "
            @selectOnChange="itemRowEventOnSelectChange"
          />
        </template>
      </v-data-table>
      <table-footer-menu
        :on-select-actions="onSelectActions"
        :selected="selected"
        :selection-map="
          (x) => {
            return {
              id: x.id,
              name: x.name,
              price: x.price,
              tax: x.tax,
              wants_invoice: x.wants_invoice,
            };
          }
        "
      />
    </template>
  </div>
</template>

<script>
import statsActions from "@/calendesk/mixins/statsActions";
import NoDataInfo from "@/components/common/NoDataInfo.vue";
import sharedList from "@/calendesk/mixins/sharedList";
import TableFooterMenu from "@/components/TableFooterMenu.vue";
import CalendeskInformationMessage from "@/lib/calendesk-js-library/components/CalendeskInformationMessage.vue";
import SimpleStoreProductFilter from "@/views/dashboard/pages/Offer/SimpleProductStore/components/SimpleStoreProductFilter.vue";
import simpleStoreActions from "@/calendesk/mixins/simpleStoreActions";
import SimpleStoreProductRow from "@/views/dashboard/pages/Offer/SimpleProductStore/components/SimpleStoreProductRow.vue";

export default {
  components: {
    SimpleStoreProductRow,
    SimpleStoreProductFilter,
    CalendeskInformationMessage,
    TableFooterMenu,
    NoDataInfo,
  },
  mixins: [simpleStoreActions, statsActions, sharedList],
  data() {
    return {
      cacheId: "simple-store-product-list-v2",
      requestActionName: "fetchSimpleStoreProductList",
      statsMode: this.$helpers.statsMode.simpleStoreProductList,
      headers: [
        {
          text: this.$trans("id"),
          align: "start",
          sortable: true,
          value: "id",
          class: "header-style",
        },
        {
          text: this.$trans("simple_store_product_list_product_name"),
          value: "simple_store_product_name",
          class: "header-style",
          sortable: false,
        },
        {
          text: this.$trans("simple_store_product_list_product_price"),
          value: "price",
          class: "header-style",
        },
        {
          text: this.$trans("wants_invoice_title"),
          value: "wants_invoice",
          sortable: false,
          class: "header-style",
        },
        {
          text: this.$trans("created_at"),
          value: "created_at",
          class: "header-style",
        },
        {
          text: this.$trans("manage"),
          value: "actions",
          sortable: false,
          class: "header-style",
          align: "end",
        },
      ],
    };
  },
  computed: {
    onSelectActions() {
      return [
        {
          title: { single: "remove", many: "remove" },
          action: this.handleDeleteProduct,
          icon: "$trash-default",
          color: "error",
          target: "product",
          class: "error--text",
        },
      ];
    },
  },
  watch: {
    getRefreshSimpleStoreProductListStatus(status) {
      if (status) {
        this.forceReload();
      }
    },
  },
};
</script>
